import React, { useEffect, useLayoutEffect, useState } from 'react'
import UserService from '../../services/user.service';

function Footer() {

    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    const getResultData = async () => {
        try {
            let resultData;
            const response = await UserService.siteInfo();
            resultData = response.data.response;
            setPhone(resultData[0].phone);
            setEmail(resultData[0].email);
            console.log(`phone=${phone}, email=${email}`);
            // localStorage.setItem("email", info.email);
            // localStorage.setItem("phone", info.phone);
        } catch (err) {
            console.log(err);
        }
    };

    useLayoutEffect(() => {
        getResultData();
    }, []);

    return (
        <footer>
            <div className="w-100 pt-100 dark-layer pb-50 opc85 position-relative">
                <div className="fixed-bg patern-bg back-blend-multiply dark-bg"
                    style={{ backgroundImage: `url(assets/images/pattern-bg.jpg)` }}></div>
                <div className="container">
                    <div className="footer-data w-100">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-lg-3">
                                <div className="widget w-100">
                                    <div className="logo">
                                        <h1 className="mb-0"><a href="index.html" title="Home"><img className="img-fluid"
                                            src="assets/images/logo2.png" alt="Logo"
                                            srcset="assets/images/logo2.png" /></a></h1>
                                    </div>
                                    <p className="mb-0">Online Quran teaching: Learn the Quran from expert tutors through interactive virtual classes, fostering spiritual growth and understanding conveniently from home.</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-9">
                                <div className="row justify-content-between">
                                    {/* <div className="col-md-6 col-sm-6 col-lg-6"> */}
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-lg-6" style={{ paddingLeft: "100px" }}>
                                            <div className="widget w-100">
                                                <h4 className="widget-title">Information</h4>
                                                <ul className="mb-0 list-unstyled w-100">
                                                    <li><a href="/" title="">Home</a>
                                                    </li>
                                                    <li><a href="/audio-listening" title="">Audio Listening</a>
                                                    </li>
                                                    <li><a href="/about" title="">About Us</a></li>
                                                    <li><a href="/contact" title="">Contact Us</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6" style={{ paddingLeft: "100px" }}>
                                            <div className="widget w-100">
                                                <h4 className="widget-title">Contact Info</h4>
                                                <ul className="cont-info-list2 mb-0 list-unstyled w-100">
                                                    <li><i className="thm-clr">P:</i>{phone}</li>
                                                    <li><i className="thm-clr">E:</i>
                                                        <a href={`mailto:${email}`} title="">{email}</a>
                                                    </li>
                                                    <li>Islamabad, Pakistan</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer